import Axios from 'axios';
import server from 'util/Server';
import Swal from 'sweetalert2';
import { sessionCaducada } from 'util/funciones'
import Storage from 'util/store/Storage'
export const getEmpresa = async (tipo, id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/empresa/tipo/" + tipo + "/padre/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.empresas;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          const datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const getEstacionByDominioId = async (id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/empresa/dominio/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          const datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const getEstructuraEds = async (id, vista) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host +
      server.puertoCentury + "/api/surtidor/configuracion-eds/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          let datos = [];
          if (vista) {
            datos = res.data.mensaje
          } else {
            res.data.mensaje.forEach((res) => {
              let isla = res.islas_id;
              res.surtidores.forEach((surti) => {
                datos.push(["Isla " + isla, "Surtidor " + surti.islas_id]);
              });
            });
          }
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          let datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const getHijos = async () => {
  return new Promise(function (resolve, reject) {
    let hijos = ""
    const { usuario } = Storage.getState()
    Axios.get(server.host + server.puertoCentury + '/api/empresa/node/' + usuario.empresas_id + '/0', { headers: { Authorization: server.obtenerToken() } })
      .then(response => {
        if (response.status === 200) {
          response.data.data.forEach((item, key) => {
            if (key === 0) {
              hijos = item.toString()
            } else {
              hijos += ',' + item.toString()
            }
          })

          Storage.dispatch({
            type: "OBTENCION_JERARQUIA_HIJOS",
            empresasHijos: hijos,
            usuarioObtenido: true
          })

          resolve(true)
        }
      })
      .catch(function (error) {
        reject(error, undefined);
      })
  });

};

export const deleteEmpresa = async (id, estado) => {
  return new Promise(function (resolve, reject) {
    const { usuario } = Storage.getState()
    const data = {
      "create_user": usuario.p_id,
      "empresas_id": usuario.empresas_id
    }
    Axios.put(server.host + server.puertoCentury + "/api/empresa/" + id + "/" + estado, data, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {

        if (res.status === server.responseSucces) {
          Swal.fire("OK", (estado === "A" ? "ACTIVADO" : "INACTIVADO") + " CORRECTAMENTE", "success")
          Storage.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: true
          });
          resolve("")
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })
}

export const actualizarIdentificadores = async (data) => {

  const mostrarErrores = ( identificadores ) => {
    return identificadores?.filter(({ procesar }) => !procesar).map(( { identificador , mensaje, alias , descripcion_origen } ) => {
      return `<p><b> ${ descripcion_origen }: ${ identificador } </b><br>${ mensaje } ( ${ alias } ) `
    })
  }

  return new Promise(function (resolve, reject) {
    Axios.post(server.host + server.puertoCentury + "/api/medios-identificacion/identificadores/estacion", data, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          Swal.fire("OK", "IDENTIFICADORES ACTUALIZADOS CORRECTAMENTE", "success")
          resolve(true)
        }
        if (res.status === server.responsePartialContent) {

          const { status = [] } = res.data

          Swal.fire({
            title: 'Error',
            html: ` <div class='overflow-auto' style = "max-height: 200px; margin-top: 10px;"  >
            identificador(es): ${mostrarErrores( status ) || "Error al actualizar los identificadores"}<br></div>`,
            icon: 'warning'
          })
          reject(false)
        }
        else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err.response.data)
        Swal.fire("Error!", err?.response.data.mensaje|| "Error al actualizar los identificadores", "error")
        reject(false)
      })
  })
}


export const obtenerIdentificadores = async (empresa_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/medios-identificacion/identificadores/" + empresa_id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(res.data.data)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err)
        reject([])
      })
  })
}

export const obtenerCierreDia = (empresa_id) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/empresa/fecha-cierre/${empresa_id}`, {
    headers: { Authorization: server.obtenerToken() }
  })
}
export const obtenerTurnosServices = (id) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/horario/empresa/` + id, {
    headers: { Authorization: server.obtenerToken() }
  })
}

export const enviarTurnosServices = (data) => {
  return Axios.post(`${server.host}${server.puertoCentury}/api/horario/empresa`,
    data,
    {
      headers: { Authorization: server.obtenerToken() }
    })
}
export const obtenerTurnosEstaciones = (codigo, empresas_id) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/horario/empresa/wacher/parametros/` + codigo + '/' + empresas_id,
    { headers: { Authorization: server.obtenerToken() } }
  )
}

export const enviarCierreDia = (empresas_id, horaInicial, horaFinal, dia_siguiente) => {
  return Axios.post(
    `${server.host}${server.puertoCentury}/api/empresa/fecha-cierre`,
    { empresas_id, fecha_inicio: horaInicial, fecha_cierre: horaFinal, dia_siguiente },
    { headers: { Authorization: server.obtenerToken() } }
  )
}

export const getLocalizacionEmpresa = async (id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/empresa/localizacion/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data[0]
          if (datos != null) {
            const localizacion = datos.localizacion
            if (JSON.stringify(localizacion) !== "{}") {
              resolve({
                lat: localizacion.lat,
                lng: localizacion.lng
              });
            }
          }
          resolve({ lat: 10.9888859, lng: -74.82026589999998 });
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};

export const actualizarLocalizacionEmpresa = async (data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/empresa/localizacion/", data, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {

        if (res.status === server.responseSucces) {
          Swal.fire({
            title: "OK!",
            text: "UBICACION ACTUALIZADA CORRECTAMENTE",
            icon: "success",
          }).then((value) => {
            if (value.value) {
              Storage.dispatch({
                type: "ACTUALIZACION_DATOS",
                actualizarvista: true
              });
              resolve(true)
            }
          });
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada([])
        } else {
          Swal.fire("ERROR", res.data.mensaje, "warning");
          resolve(false)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })
};

export const getSelectEmpresasDisp = async () => {
  return new Promise(function (resolve, reject) {
    const { empresasHijos } = Storage.getState()
    Axios.get(server.host + server.puertoCentury + "/api/empresa/select/empresa/" + empresasHijos, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(res.data.data);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          resolve([]);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined)
      })

  })
};

export const getIslasByEmpresaId = async (id_empresa) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + `/api/equipo/select/empresa/${id_empresa}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          const datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });

};


export const getDispRegistradoById = async (id_empresa) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + `/api/empresa/gestionEDS/acciones/${id_empresa}`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          const datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

export const getInfoProveedores = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + `/api/persona/mostrar-proveedor/obtener/proveedores`, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data;
          resolve(datos);
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          const datos = [];
          resolve(datos);
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      });
  });
};

export const guardarDispositivosEds = (body) => {
  return new Promise(function (resolve, reject) {
    Axios.post(server.host + server.puertoCentury + "/api/empresa/gestionEDS/acciones", body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces || res.status === server.responseOK) {
          Swal.fire("OK", "DISPOSITIVOS GUARDADOS CORRECTAMENTE", "success")
          resolve(res.data)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.error(err)
        if (err.response.status === server.responseError) {
          Swal.fire("Error", err.response.data.mensaje, "error")
          resolve([])
        } else if (err.response.status === server.responseErrorInternal) {
          Swal.fire("Error", err.response.data.mensajeError, "error")
          resolve([])
        }
        reject(err, undefined)
      })
  })
};

export const ActualizarDispositivosEds = (id, estado) => {
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + `/api/empresa/gestionEDS/desactivar/EDS/${id}/${estado}`, {}, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces || res.status === server.responseOK) {
          Swal.fire("OK", `DISPOSITIVO ${estado === 'A' ? 'HABILITADO' : 'DESHABILITADO'} CORRECTAMENTE`, "success")
          resolve(res.data)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.error(err)
        if (err.response.status === server.responseError) {
          Swal.fire("Error", err.response.data.mensaje, "error")
          resolve([])
        } else if (err.response.status === server.responseErrorInternal) {
          Swal.fire("Error", err.response.data.mensajeError, "error")
          resolve([])
        }
        reject(err, undefined)
      })
  })
};

export const editarDispEds = (body) => {
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/empresa/gestionEDS/acciones", body, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces || res.status === server.responseOK) {
          Swal.fire({
            title: "OK!",
            text: "DISPOSITIVOS EDITADOS CORRECTAMENTE",
            icon: "success",
          })
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada([])
        } else {
          Swal.fire("ERROR", res.data.mensaje, "warning");
          resolve(false)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

};

export const estacionesActivasBeta4 = () => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/empresa/beta-cuatro/estaciones/beta4`, { headers: { Authorization: server.obtenerToken() } })
}

export const configurarEstacionBeta4 = (id, activar) => {
  return Axios.put(`${server.host}${server.puertoCentury}/api/empresa/beta-cuatro/${id}/${activar ? 'add' : 'remove'}`, undefined, { headers: { Authorization: server.obtenerToken() } })
}

export const saveConfiguracionEds = (data, cerrarConfiguracionEds) => {
  Axios.post(server.host + server.puertoCentury + "/api/surtidor", data, { headers: { Authorization: server.obtenerToken() } })
    .then(Response => {
      if (Response.status === server.responseSucces) {
        if (Response.data.success) {
          Swal.fire({
            title: "OK",
            text: `${Response.data.mensaje}`,

            icon: "success",
          }).then((value) => {
            if (value.value) {
              Storage.dispatch({
                type: "ACTUALIZACION_DATOS",
                actualizarvista: true
              });

              cerrarConfiguracionEds()

            }
          });
        } else {
          Swal.fire("ERROR!", Response.data.mensaje, "warning");
        }
      } else if (Response.status === server.responseSesionCaducada) {
        Swal.fire("ERROR", "¡Su session ha caducado, por favor ingrese nuevamente!", "warning");
        setTimeout(function () { window.location.href = '/'; }, 2000);
      } else {
        Swal.fire("ERROR", Response.data.mensaje, "warning");
      }
    })
    .catch(Err => {
      console.error(Err);
      Swal.fire("ERROR", "¡Ha ocurrido un error, por favor intentelo mas tarde!", "warning");
    })
}

export const updateConfiguracionEds = (data, cerrarConfiguracionEds) => {
  Axios.put(server.host + server.puertoCentury + "/api/surtidor", data, { headers: { Authorization: server.obtenerToken() } })
    .then(Response => {
      if (Response.status === server.responseSucces) {
        console.log(Response.data.mensaje)
        Swal.fire({
          title: "OK!",
          text: Response.data.mensaje,
          icon: "success",
        }).then((value) => {
          if (value.value) {
            Storage.dispatch({
              type: "ACTUALIZACION_DATOS",
              actualizarvista: true
            });
            cerrarConfiguracionEds()

          }
        });

      } else if (Response.status === server.responseSesionCaducada) {
        Swal.fire("ERROR", "¡Su session ha caducado, por favor ingrese nuevamente!", "warning");
        setTimeout(function () { window.location.href = '/'; }, 2000);
      } else {
        Swal.fire("ERROR", Response.data.mensaje, "warning");
      }
    })
    .catch(Err => {
      console.error(Err);
      Swal.fire("ERROR", "¡Ha ocurrido un error, por favor intentelo mas tarde!", "warning");
    })
}
